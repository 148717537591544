<template>
  <div @click.self="stopCamera" class="liveness-check">
    <h2 class="text-white">Liveness Check</h2>

    <video ref="video" class="video-feed" autoplay></video>

    <div class="controls">
      <button
        type="button"
        class="flex justify-center items-center capture-button focus:outline-none"
        @click="captureImage"
      >
        <i class="fas fa-camera"></i>
      </button>
    </div>

    <!-- Lightbox Modal for Preview -->
    <div
      v-if="showLightbox"
      class="lightbox-overlay"
      @click.self="closeLightbox"
    >
      <div class="lightbox-content">
        <h3>Preview Captured Image</h3>
        <img :src="capturedImageUrl" alt="Captured Image" />
        <div class="actions">
          <button class="focus:outline-none submit-button" @click="submitImage">
            Submit
          </button>
          <button
            class="focus:outline-none cancel-button"
            @click="closeLightbox"
          >
            Retake
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "LivenessCheck",
  setup(props, { emit }) {
    const video = ref(null);
    const capturedImageUrl = ref(null);
    const capturedImageFile = ref(null);
    const showLightbox = ref(false);
    let videoStream = null;

    // Initialize camera
    const initializeCamera = async () => {
      try {
        videoStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        video.value.srcObject = videoStream;
      } catch (error) {
        console.error("Error accessing the camera:", error);
      }
    };

    // Capture image from video feed
    const captureImage = () => {
      if (!video.value) return;

      const canvas = document.createElement("canvas");
      canvas.width = video.value.videoWidth;
      canvas.height = video.value.videoHeight;
      const context = canvas.getContext("2d");

      context.drawImage(video.value, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (blob) {
          capturedImageFile.value = new File([blob], "liveness-check.png", {
            type: "image/png",
          });
          capturedImageUrl.value = URL.createObjectURL(capturedImageFile.value);
          showLightbox.value = true;
        } else {
          console.error("Failed to capture image.");
        }
      }, "image/png");
    };

    // Submit captured image
    const submitImage = () => {
      if (capturedImageFile.value) {
        emit("imageCaptured", capturedImageFile.value);
        closeLightbox();
        stopCamera();
      } else {
        console.error("No image file to submit.");
      }
    };

    // Close lightbox modal
    const closeLightbox = () => {
      showLightbox.value = false;
      capturedImageUrl.value = null;
      capturedImageFile.value = null;
    };

    // Stop camera stream
    const stopCamera = () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
        videoStream = null;
      }
    };

    onMounted(() => {
      initializeCamera();
    });

    onBeforeUnmount(() => {
      stopCamera();
    });

    return {
      video,
      capturedImageUrl,
      showLightbox,
      captureImage,
      submitImage,
      closeLightbox,
    };
  },
};
</script>

<style scoped>
/* General Styles */
.liveness-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

h2 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
}

.video-feed {
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #000;
}

.controls {
  margin-top: 20px;
}

.capture-button {
  background-color: #11453c;
  color: #fff;
  /* padding: 12px 24px; */
  width: 48px;
  height: 48px;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 102, 255, 0.3);
}

.capture-button:hover {
  background-color: #0052cc;
  transform: translateY(-2px);
}

/* Lightbox Styles */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.lightbox-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  animation: slideIn 0.3s ease;
}

.lightbox-content h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #111;
}

.lightbox-content img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 12px;
  margin-bottom: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  min-width: 120px;
}

.submit-button {
  background-color: #11453c;
  color: #fff;
  box-shadow: 0 4px 10px rgba(40, 167, 69, 0.3);
}

.submit-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #ea4e4b;
  color: #fff;
  box-shadow: 0 4px 10px rgba(220, 53, 69, 0.3);
}

.cancel-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .lightbox-content {
    padding: 20px;
  }

  .capture-button,
  .submit-button,
  .cancel-button {
    font-size: 14px;
    padding: 10px 20px;
  }
  .video-feed {
    width: 100%;
    max-width: 600px;
    height: 400px;
  }
}
</style>
