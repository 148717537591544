<template>
  <!--Profile form start-->
  <form
    v-if="activeStep === 4"
    @submit.prevent="handleEmit"
    class="grid grid-cols-2 items-center gap-x-5 w-full md:w-8/12 md:mx-auto mt-1"
  >
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="First Name"
        v-model="personalKycArgs.firstName"
        placeholder="First Name"
        required
        readonly
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Middle Name"
        v-model="personalKycArgs.middleName"
        placeholder="Middle Name"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Last Name"
        v-model="personalKycArgs.lastName"
        placeholder="Last Name"
        required
        readonly
      />
    </div>
    <!-- <easiCalendar :date="dateInput" @select="handleDateSelect" /> -->
    <!-- <easiDate
      label="Enter date of birth"
      placeholder="Enter date of birth"
      class=""
      min
      @date="($event) => (personalKycArgs.dob = $event)"
    /> -->
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        type="date"
        label="Enter date of birth"
        placeholder="Enter date of birth"
        v-model="personalKycArgs.dob"
        required
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Enter NIN"
        placeholder="Enter NIN"
        type="number"
        v-model="personalKycArgs.nin"
        required
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Enter Bank Verification Number (BVN)"
        placeholder="Enter Bank Verification Number (BVN)"
        v-model="personalKycArgs.bvn"
        type="number"
        required
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiSelectInput2
        :hideCheckBox="true"
        label="Select ID type"
        placeholder="Select ID type"
        :value="personalKycArgs.idType"
        :options="Options"
        :noSearch="true"
        @update="($event) => (personalKycArgs.idType = $event)"
        class="mt-6"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Enter ID Number"
        placeholder="Enter ID Number"
        v-model="personalKycArgs.idValue"
        required
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <UploadComponent
        class="mt-5"
        label="Upload a clear picture of the ID Card"
        :dottedNew="true"
        :externalFile="files.idCardPhoto"
        @fileUrl="files.idCardPhoto = $event"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <UploadComponent
        class="mt-5"
        label="Upload passport photo"
        :externalFile="files.passportPhoto"
        :dottedNew="true"
        @fileUrl="files.passportPhoto = $event"
      />
    </div>

    <div class="w-full mt-6 col-span-2">
      <UploadComponent
        label="Liveness Check"
        :dottedNew="true"
        video
        isKyc
        :imageLink="files.liveliness ? 'Capture' : ''"
        :externalFile="files.liveliness"
        @capture="showCapture = true"
      />
    </div>

    <easiModal
      :lite="true"
      :isBorder="false"
      :compliance="true"
      @close="showCapture = false"
      v-if="showCapture"
    >
      <CaptureComponent
        @imageCaptured="
          ($event) => {
            files.liveliness = $event;
            showCapture = false;
          }
        "
      />
    </easiModal>
    <div
      class="w-full col-span-2 p-1 rounded-md mt-6 md:mt-10 text-center bg-newAsh text-xs text-dark-600"
    >
      If you need to make any changes to your profile details, please contact
      our
      <a class="text-blue-500" target="_blank" href="mailto:hi@myeazipay.com"
        >support team</a
      >
    </div>

    <div
      class="flex md:flex-row flex-col-reverse items-center justify-center mt-7 mb-8 gap-6 col-span-2"
    >
      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #88a29d;
          background-color: #e7eceb;
        "
        class="focus:outline-none w-full md:w-44"
        @click="$router.push({ name: 'AdminDashboard' })"
      >
        Skip for Now
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #11453b;
          border: 1px solid #11453b;
        "
        class="focus:outline-none w-full md:w-44"
        v-if="activeStep > 4"
      >
        Back
      </button>

      <div class="w-full md:w-44">
        <easiButton
          size="small"
          type="submit"
          block
          style="
            border-radius: 24px;
            font-size: 14px;
            padding: 10px 20px;
            gap: 8px;
            color: #ffffff;
            background-color: #11453b;
          "
          class="focus:outline-none"
          :loading="propLoad"
        >
          Save & Continue
        </easiButton>
      </div>
    </div>
  </form>
  <!--Profile form end-->

  <!--Business profile start-->
  <form
    v-if="activeStep === 5"
    class="grid grid-cols-2 items-center gap-x-5 w-full md:w-8/12 md:mx-auto mt-1"
    @submit.prevent="emitNext"
  >
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Business Name"
        placeholder="Business Name"
        v-model="businessKycArgs.businessName"
        :error="errorRules.businessName"
        required
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiSelectInput2
        :hideCheckBox="true"
        label="Business Type"
        placeholder="Business Type"
        :options="businessTypeOptions"
        @update="($event) => (businessKycArgs.businessType = $event)"
        :value="businessKycArgs.businessType"
        class="mt-4"
        :noSearch="true"
        :error="errorRules.businessType"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        label="Enter Business CAC Number"
        placeholder="Enter Business CAC Number"
        v-model="businessKycArgs.cacNumber"
        required
        :error="errorRules.cacNumber"
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiSelectInput2
        :hideCheckBox="true"
        label="Business Location"
        placeholder="Business Location"
        :options="statesData"
        :value="businessKycArgs.location"
        @update="($event) => (businessKycArgs.location = $event)"
        class="mt-4"
        :error="errorRules.location"
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <UploadComponent
        class="mt-5"
        label="Upload Proof of Address"
        :dottedNew="true"
        @fileUrl="businessFiles.proofOfAddress = $event"
        :externalFile="businessFiles.proofOfAddress"
        :error="errorRules.proofOfAddress"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <UploadComponent
        class="mt-5"
        label="Business Logo"
        :dottedNew="true"
        :externalFile="businessKycArgs.logo"
        @fileUrl="businessKycArgs.logo = $event"
        :error="errorRules.logo"
      />
    </div>
    <div
      class="flex flex-col-reverse md:flex-row items-center justify-center mt-7 mb-8 gap-6 col-span-2"
    >
      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #88a29d;
          background-color: #e7eceb;
        "
        class="focus:outline-none w-full md:w-44"
      >
        Skip for Now
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #11453b;
          border: 1px solid #11453b;
        "
        class="focus:outline-none w-full md:w-44"
        @click="$emit('back')"
      >
        Back
      </button>

      <div class="w-full md:w-44">
        <easiButton
          size="small"
          type="submit"
          block
          style="
            border-radius: 24px;
            font-size: 14px;
            padding: 10px 20px;
            gap: 8px;
            color: #ffffff;
            background-color: #11453b;
          "
          class="focus:outline-none"
          :loading="propLoad"
        >
          Save & Continue
        </easiButton>
      </div>
    </div>
  </form>
  <!--Business profile end-->

  <!--Director form start-->
  <form
    v-if="activeStep === 6"
    class="grid grid-cols-2 items-center gap-x-5 w-full md:w-8/12 md:mx-auto mt-1"
    @submit.prevent="handleSubmitDirector(false)"
  >
    <div class="col-span-2 flex flex-wrap items-center gap-4">
      <button
        @click="handleSubmitDirector(true)"
        type="button"
        style="
          border-radius: 8px;
          border: 1px solid #e9e9e9;
          padding: 10px 10px 10px 8px;
          gap: 8px;
          background-color: #f3f6f5;
          box-shadow: 0px 6px 12px 0px rgba(170, 170, 170, 0.11);

          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: #191919;
        "
        class="flex items-center gap-4 focus:outline-none"
      >
        <img src="../../assets/icons/plus.svg" alt="" class="mr-1" />
        <span style="font-weight: 500"> Add new director</span>
      </button>

      <img
        v-show="directorLoading"
        src="../../assets/img/just-loading.gif"
        class="w-4"
        alt=""
      />
      <span
        v-show="directorLoading"
        style="
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: #474747;
        "
        >Saving</span
      >
      <img
        v-show="!directorLoading && finishedLoading"
        src="../../assets/img/full-loader.gif"
        class="w-4"
        alt=""
      />

      <div
        v-for="(director, i) in allFounders"
        :key="director._id"
        style="
          border-radius: 8px;
          padding: 8px;
          display: flex;

          gap: 8px;
        "
        class="border items-center"
        :class="
          i !== active
            ? 'bg-highlight border-lighterGray'
            : 'bg-mint border-paleShade'
        "
      >
        <img src="../../assets/icons/round-profile.svg" alt="" />
        <span
          style="
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: #474747;
          "
        >
          {{ director.fullName }}
        </span>
        <button
          @click="handleDeleteModal(director._id)"
          type="button"
          class="focus:outline-none bg-none"
        >
          <img
            src="../../assets/icons/delete.svg"
            alt=""
            class="cursor-pointer"
            @click="showModal = true"
          />
        </button>
      </div>
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiSelectInput
        required
        class="mt-5"
        @update="args.title = $event"
        :value="args.title"
        label="Select Title"
        placeholder="Select Title"
        :noSearch="true"
        :options="[
          'Mr',
          'Mrs',
          'Miss',
          'Dr',
          'Prof',
          'Chief',
          'Engr',
          'Pastor',
          'Rev',
          'Hon',
          'Sir',
          'Lady',
          'Alhaji',
          'Alhaja',
          'Madam',
          'Baba',
          'Mama',
        ]"
        :error="errorRules.title"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.firstName"
        label="First Name"
        placeholder="Enter First Name"
        :required="allFounders && allFounders.length ? false : true"
        :error="errorRules.firstName"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.middleName"
        label="Middle Name"
        placeholder="Enter Middle Name"
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        :required="allFounders && allFounders.length ? false : true"
        :error="errorRules.lastName"
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.role"
        label="Director's role"
        placeholder="Director's role"
        :required="allFounders && allFounders.length ? false : true"
        :error="errorRules.role"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.phoneNumber"
        label="Phone Number"
        placeholder="Enter Phone Number"
        :required="allFounders && allFounders.length ? false : true"
        :error="errorRules.phoneNumber"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <easiTextInput
        v-model="args.email"
        label="Email Address"
        placeholder="Enter Email Address"
        :required="allFounders && allFounders.length ? false : true"
        :error="errorRules.email"
      />
    </div>
    <div class="w-full md:col-span-1 col-span-2">
      <easiSelectInput2
        :hideCheckBox="true"
        label="Select ID type"
        placeholder="Select ID type"
        :options="[
          { label: 'International Passport', value: 'interntionlPassport' },
          { label: 'National ID', value: 'nin' },
        ]"
        :noSearch="true"
        @update="($event) => (args.idType = $event)"
        :value="args.idType"
        class="mt-5"
        :error="errorRules.idType"
      />
    </div>
    <div
      v-if="args.idType && args.idType.length"
      class="w-full md:col-span-1 col-span-2"
    >
      <UploadComponent
        class="mt-4"
        label="Upload National ID card or Int. passport"
        :dottedNew="true"
        @fileUrl="args.personalDocument[args.idType] = $event"
        :error="errorRules.personalDocument[args.idType]"
      />
    </div>

    <div class="w-full md:col-span-1 col-span-2">
      <UploadComponent
        class="mt-4"
        label="Upload passport photo"
        :dottedNew="true"
        @fileUrl="args.pictureUrl = $event"
        :error="errorRules.pictureUrl"
      />
    </div>

    <div class="w-full col-span-2">
      <UploadComponent
        class="mt-4 col-span-2"
        label="Director's Signature"
        :dottedNew="true"
        @fileUrl="args.signatureImage = $event"
        :error="errorRules.signatureImage"
      />
    </div>

    <div
      class="flex flex-col-reverse md:flex-row items-center justify-center mt-7 mb-8 gap-6 col-span-2"
    >
      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #88a29d;
          background-color: #e7eceb;
        "
        class="focus:outline-none w-full md:w-44"
      >
        Skip for Now
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #11453b;
          border: 1px solid #11453b;
        "
        class="focus:outline-none w-full md:w-44"
        @click="$emit('back')"
      >
        Back
      </button>

      <div class="w-full md:w-44">
        <easiButton
          size="small"
          type="submit"
          block
          style="
            border-radius: 24px;
            font-size: 14px;
            padding: 10px 20px;
            gap: 8px;
            color: #ffffff;
            background-color: #11453b;
          "
          class="focus:outline-none"
          :loading="directorLoading"
        >
          Save & Continue
        </easiButton>
      </div>
    </div>
  </form>
  <!--Director form end-->

  <!--Businees docs 1 start-->
  <form
    v-if="activeStep === 7"
    class="grid grid-cols-2 items-center gap-x-5 w-full md:w-8/12 md:mx-auto mt-1"
    @submit.prevent="handleEmitForBusiness"
  >
    <div
      class="w-full"
      :class="
        businessKycArgs.businessType === 'limited_liability_company'
          ? 'col-span-2'
          : 'md:col-span-1 col-span-2'
      "
    >
      <UploadComponent
        class="mt-4"
        label="Upload CAC document"
        :dottedNew="true"
        :externalFile="businessFiles.cac_document"
        @fileUrl="businessFiles.cac_document = $event"
        :error="errorRules.cac_document"
      />
    </div>

    <div
      v-if="businessKycArgs.businessType === 'limited_liability_company'"
      class="w-full md:col-span-1 col-span-2"
    >
      <UploadComponent
        class="mt-4"
        label="Upload MEMART"
        :dottedNew="true"
        :externalFile="businessFiles.memart"
        @fileUrl="businessFiles.memart = $event"
        :error="errorRules.memart"
      />
    </div>

    <div
      v-if="businessKycArgs.businessType === 'limited_liability_company'"
      class="w-full md:col-span-1 col-span-2"
    >
      <UploadComponent
        class="mt-6"
        label="Upload Form C02"
        :dottedNew="true"
        :externalFile="businessFiles.form_c02"
        @fileUrl="businessFiles.form_c02 = $event"
        :error="errorRules.form_c02"
      />
    </div>

    <div
      v-if="businessKycArgs.businessType === 'limited_liability_company'"
      class="w-full md:col-span-1 col-span-2"
    >
      <UploadComponent
        class="mt-6"
        label="Upload Form C07"
        :dottedNew="true"
        :externalFile="businessFiles.form_c07"
        @fileUrl="businessFiles.form_c07 = $event"
        :error="errorRules.form_c07"
      />
    </div>

    <div
      class="flex flex-col-reverse md:flex-row items-center justify-center mt-7 mb-8 gap-6 col-span-2"
    >
      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #88a29d;
          background-color: #e7eceb;
        "
        class="focus:outline-none w-full md:w-44"
      >
        Skip for Now
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #11453b;
          border: 1px solid #11453b;
        "
        class="focus:outline-none w-full md:w-44"
        @click="$emit('back')"
      >
        Back
      </button>

      <div class="w-full md:w-44">
        <easiButton
          size="small"
          type="submit"
          block
          style="
            border-radius: 24px;
            font-size: 14px;
            padding: 10px 20px;
            gap: 8px;
            color: #ffffff;
            background-color: #11453b;
          "
          class="focus:outline-none"
          :loading="propLoad"
        >
          Finish
        </easiButton>
      </div>
    </div>
  </form>
  <!--Businees docs 1 end-->

  <!--Businees docs CAC start-->
  <!-- <form
    v-if="activeStep === 8"
    class="grid grid-cols-2 items-center gap-x-5 w-full md:w-8/12 md:mx-auto mt-1"
  >
    <UploadComponent
      class="mt-6 col-span-2"
      label="Upload CAC document"
      :dottedNew="true"
      :externalFile="businessFiles.six_months_bank_statement"
      @fileUrl="businessFiles.six_months_bank_statement = $event"
    />

    <div class="flex items-center justify-center mt-7 mb-8 gap-x-6 col-span-2">
      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #88a29d;
          background-color: #e7eceb;
        "
        class="focus:outline-none w-44"
      >
        Skip for Now
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #11453b;
          border: 1px solid #11453b;
        "
        class="focus:outline-none w-44"
        @click="$emit('back')"
      >
        Back
      </button>

      <button
        type="button"
        style="
          border-radius: 24px;
          font-size: 14px;
          padding: 10px 20px;
          gap: 8px;
          color: #ffffff;
          background-color: #11453b;
        "
        class="focus:outline-none w-44"
        @click="$emit('next')"
      >
        Save & Continue
      </button>
    </div>
  </form>-->
  <!--Businees docs CAC end-->

  <easiModal
    v-if="showModal"
    @close="showModal = false"
    pixelRounded="40px"
    :isBorder="false"
    :showCloseButton="false"
  >
    <template v-slot:header>
      <p
        style="
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #292a29;
        "
        class="mt-6"
      >
        Delete Profile?
      </p>
    </template>

    <p
      style="
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #515251;
      "
      class="my-2"
    >
      You are about to delete this director’s profile.
    </p>

    <div class="flex items-center w-11/12 mx-auto mt-6 justify-center gap-4">
      <button
        style="
          border-radius: 24px;
          border: 1px solid #e7e8e7;
          padding: 14px 20px;
          gap: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #11453b;
          width: 40%;
        "
        class="focus:outline-none"
        @click="showModal = false"
      >
        Cancel
      </button>
      <easiButton
        block
        style="
          border-radius: 24px;
          border: 1px solid #e7e8e7;
          gap: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          width: 100%;
        "
        class="md:w-44 w-full focus:outline-none"
        :loading="loading.delete"
        @click="deleteFounder"
      >
        Yes, delete
      </easiButton>
    </div>
  </easiModal>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from "vue";
import NigerianStates from "@/utils/states";
import datePicker from "@/components/global/DatePicker.vue";
import UploadComponent from "@/components/global/UploadComponent";
import CaptureComponent from "@/components/global/CaptureComponent";
import { KycClasses } from "./ClassKyc";
import { DBClass } from "../../composable/IndexDB";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { resetObj, base64ToFile, fileToBase64, addData, getData, deleteData } =
  helperFunctions;
const store = useDataStore();

const props = defineProps({
  activeStep: { type: Number, default: 4 },
  propLoad: { type: Boolean, default: false },
});
const emit = defineEmits(["next", "back", "finish"]);
const active = ref(1);
const showModal = ref(false);
const showCapture = ref(false);
const allFounders = computed(
  () => store.getDirectors && store.getDirectors.data
);
const loading = reactive({ delete: false });
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const Options = ref([
  {
    label: "National ID",
    value: "national_id",
  },
  {
    label: "Voters Card",
    value: "voters_card",
  },
  {
    label: "NIN",
    value: "nin_slip",
  },
  {
    label: "Drivers License",
    value: "drivers_license",
  },
  {
    label: "International Passport",
    value: "international_passport",
  },
]);

const businessTypeOptions = ref([
  {
    label: "Limited Liability Company (Ltd)",
    value: "limited_liability_company",
  },
  {
    label: "Non-Governmental Organization (NGO)",
    value: "non_governmental_organization",
  },
  {
    label: "Sole Proprietorship",
    value: "SOLE_PROPRIETORSHIP",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Corporation",
    value: "CORPORATION",
  },
  {
    label: "Public Limited Company (PLC)",
    value: "PUBLIC_LIMITED_COMPANY",
  },
  {
    label: "Government Parastatal",
    value: "GOVERNMENTAL_PARASTATAL",
  },
]);
const deleteId = ref("");
const handleDeleteModal = (id) => {
  deleteId.value = id;
  showModal.value = true;
};
const files = ref({ idCardPhoto: null, passportPhoto: null, liveliness: null });
const personalKycArgs = ref({
  firstName: "",
  lastName: "",
  middleName: "",
  dob: "",
  nin: "",
  bvn: "",
  idType: "",
  idValue: "",
});

const handleEmit = async () => {
  const data = {
    ...personalKycArgs.value,
    ...files.value,
  };
  const storeFile = {};
  for (const key in files.value) {
    storeFile[key] = await fileToBase64(files.value[key]);
  }

  const profileData = {
    id: "kycProfileInput",
    content: JSON.stringify({
      personalKycArgs: personalKycArgs.value,
      files: storeFile,
    }),
  };

  await DBClass.DB.addData(profileData);
  // window.localStorage.setItem(
  //   "kycProfileInput",
  //   JSON.stringify({
  //     personalKycArgs: personalKycArgs.value,
  //     files: storeFile,
  //   })
  // );

  emit("next", data);
};
const businessKycArgs = ref({
  businessName: "",
  businessType: "",
  cacNumber: "",
  location: "",
  logo: null,
  companyDocuments: [],
});
const businessFiles = ref({
  cac_document: null,
  // six_months_bank_statement: null,
  memart: null,
  form_c02: null,
  form_c07: null,
  // companyDirectors: null,
  proofOfAddress: null,
  // trusteeDocument: null,
  // ngoConstitution: null,
});
const emitNext = async () => {
  const data = {
    ...businessKycArgs.value,
    ...businessFiles.value,
  };
  const storeFile = {};
  for (const key in businessFiles.value) {
    storeFile[key] = await fileToBase64(businessFiles.value[key]);
  }
  const businessData = {
    id: "businessKycInput",
    content: JSON.stringify({
      logo: await fileToBase64(businessKycArgs.value.logo),
      businessKycArgs: businessKycArgs.value,
      businessFiles: storeFile,
    }),
  };

  await DBClass.DB.addData(businessData);
  // window.localStorage.setItem(
  //   "businessKycInput",
  //   JSON.stringify({
  //     businessKycArgs: businessKycArgs.value,
  //     businessFiles: storeFile,
  //   })
  // );

  emit("next");
};
const args = ref({
  title: "",
  firstName: "",
  lastName: "",
  middleName: "", //All three should be joined to fullName
  email: "",
  phoneNumber: "",
  address: "",
  bvn: "",
  idUrl: "",
  idType: "",
  pictureUrl: "",
  signatureImage: null,
  personalDocument: { nin: null, interntionlPassport: null },
  execType: "DIRECTOR",
});

const errorRules = ref({
  ...businessKycArgs.value,
  ...businessFiles.value,
  ...args.value,
});
const directorLoading = ref(false);
const finishedLoading = ref(false);
async function handleSubmitDirector(save) {
  //validate input first
  // const isValid = KycClasses.Kyc.validateInput(args.value, [
  //   "title",
  //   "firstName",
  //   "lastName",
  //   "email",
  //   "phoneNumber",
  //   "pictureUrl",
  //   "signatureImage",
  //   "idType",
  // ]);
  // if (isValid !== true) {
  //   isValid.forEach((item) => {
  //     errorRules.value[item] = "This field is required";
  //   });
  //   return;
  // }
  // window.localStorage.setItem("directorInput", JSON.stringify(args.value));

  //No need for this since it doesn't affect the flow
  // const directorData = {
  //   id: "directorInput",
  //   content: JSON.stringify(args.value),
  // };

  // await DBClass.DB.addData(directorData);

  //check if founder(s) exist and if the form is empty,then move to next screen
  if (
    allFounders.value &&
    allFounders.value.length &&
    args.value.firstName &&
    !args.value.firstName.trim().length
  ) {
    emit("next");
    return;
  }
  directorLoading.value = true;

  try {
    const res = await KycClasses.CompanyKyc.createExec(args.value);
    if (res.success) {
      args.value.signatureImage = null;
      args.value.pictureUrl = null;
      args.value.personalDocument[args.value.idType] = null;
      args.value = resetObj(args.value);
      finishedLoading.value = true;

      setTimeout(() => {
        finishedLoading.value = false;
      }, 2000);
      if (!save) {
        emit("next");
      }
    } else {
      for (const key in res.error) {
        errorRules.value[key] = res.error[key][0];
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    directorLoading.value = false;
  }
}

function handleEmitForBusiness() {
  const companyDocuments = [];
  for (const doc in businessFiles.value) {
    if (businessFiles.value[doc]) {
      companyDocuments.push({ document: businessFiles.value[doc], name: doc });
    }
  }
  const data = {
    ...personalKycArgs.value,
    ...files.value,
    ...businessKycArgs.value,
    companyDocuments,
  };
  // const isValid = KycClasses.Kyc.validateInput(data, [
  //   "businessName",
  //   "businessType",
  //   "cacNumber",
  //   "document",
  //   "location",
  //   "logo",
  //   "companyDocuments",
  //   "proofOfAddress",
  //   "cac_document",
  //   "bvn",
  //   "idCardPhoto",
  //   "idType",
  //   "idValue",
  //   "liveliness",
  //   "nin",
  //   "passportPhoto",
  // ]);
  // if (isValid !== true) {
  //   isValid.forEach((item) => {
  //     errorRules.value[item] = "This field is required";
  //   });
  //   return;
  // }
  emit("finish", data);
}
const watchStep = computed(() => props.activeStep);

// watch(
//   watchStep,
//   () => {
//     const user = JSON.parse(window.localStorage.getItem("userDetails"));
//     const business = JSON.parse(window.localStorage.getItem("businessDetails"));

//     const personalKycInput = window.localStorage.getItem("kycProfileInput");
//     const businessKycInput = window.localStorage.getItem("businessKycInput");
//     const directorInput = window.localStorage.getItem("directorInput");

//     if (personalKycInput) {
//       const input = JSON.parse(personalKycInput);
//       personalKycArgs.value = input.personalKycArgs;
//       files.value = input.files;
//     } else {
//       personalKycArgs.value.firstName = (user && user.firstName) || "";
//       personalKycArgs.value.lastName = (user && user.lastName) || "";
//     }
//     if (businessKycInput) {
//       const input = JSON.parse(businessKycInput);
//       personalKycArgs.value = input.businessKycArgs;
//       businessFiles.value = input.businessFiles;
//     } else {
//       // businessKycArgs.value.businessName = (user && user.companyName) || "";
//       businessKycArgs.value.businessType =
//         (business && business.businessType) || "";
//     }
//     if (directorInput) {
//       const input = JSON.parse(directorInput);
//       args.value = input;
//     }
//   }
//   // { immediate: true }
// );

const deleteFounder = async () => {
  loading.delete = true;
  try {
    const res = await KycClasses.CompanyKyc.deleteExec(deleteId.value);
    if (res.success) {
      showModal.value = false;
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.delete = false;
  }
};

onMounted(async () => {
  const user = await JSON.parse(window.localStorage.getItem("userDetails"));
  const business = await JSON.parse(
    window.localStorage.getItem("businessDetails")
  );

  // const personalKycInput = window.localStorage.getItem("kycProfileInput");
  // const businessKycInput = window.localStorage.getItem("businessKycInput");
  // const directorInput = window.localStorage.getItem("directorInput");

  const personalKycInput = await DBClass.DB.getData("kycProfileInput");
  const businessKycInput = await DBClass.DB.getData("businessKycInput");
  const directorInput = await DBClass.DB.getData("directorInput");

  if (personalKycInput) {
    const fileStore = {};
    const input = await JSON.parse(personalKycInput.content);
    personalKycArgs.value = input.personalKycArgs;
    for (const key in input.files) {
      fileStore[key] = base64ToFile(input.files[key], key);
    }
    files.value = fileStore;
  } else {
    personalKycArgs.value.firstName = (user && user.firstName) || "";
    personalKycArgs.value.lastName = (user && user.lastName) || "";
  }
  if (businessKycInput) {
    const businessStore = {};
    const input = await JSON.parse(businessKycInput.content);
    delete input.businessKycArgs.logo; //remove logo from here;
    businessKycArgs.value = input.businessKycArgs;
    businessKycArgs.value.logo = await base64ToFile(input["logo"], "logo");
    for (const key in input.businessFiles) {
      businessStore[key] = base64ToFile(input.businessFiles[key], key);
    }
    businessFiles.value = businessStore;
  } else {
    // businessKycArgs.value.businessName = (user && user.companyName) || "";
    businessKycArgs.value.businessType =
      (business && business.businessType) || "";
  }
  if (directorInput) {
    const input = await JSON.parse(directorInput.content);
    args.value = input;
  }
  //Also populate business type automatically here

  await KycClasses.CompanyKyc.queryCompanyExec(); //query the execs
});
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr; /* 1 column by default */
  align-items: center; /* Center items vertically */
  gap: 0 1.25rem; /* Horizontal gap of 1.25rem (20px) */
  width: 100%; /* Full width */
  margin-top: 0.25rem; /* Top margin of 0.25rem (4px) */
}

/* Media query for larger screens (lg: 1024px and up) */
@media (min-width: 1024px) {
  .container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    width: 66.666667%; /* 8/12 width */
    margin-left: auto;
    margin-right: auto; /* Center the container horizontally */
  }
}
</style>
