import axios from "axios";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const { uploadToREST, uploadFileToServer } = helperFunctions;
const store = useDataStore();
const { mutate, query } = store;

const toast = useToast();

class Kyc {
  constructor(
    bvn,
    idCardPhoto,
    idType,
    idValue,
    liveliness,
    nin,
    passportPhoto,
    middleName,
    dob,
    businessName,
    businessType,
    cacNumber,
    companyDocuments,
    location,
    logo
  ) {
    this.bvn = bvn;
    this.idCardPhoto = idCardPhoto;
    this.idType = idType;
    this.idValue = idValue;
    this.liveliness = liveliness;
    this.nin = nin;
    this.passportPhoto = passportPhoto;
    this.middleName = middleName;
    this.dob = dob;
    this.businessName = businessName;
    this.businessType = businessType;
    this.cacNumber = cacNumber;
    this.companyDocuments = companyDocuments;
    this.location = location;
    this.logo = logo;
  }

  submitKycForm() {
    throw new Error("Method should be called from a sub class");
  }
  static validateInput(args, requiredFields, parentKey = null) {
    let missingFields = [];

    requiredFields.forEach((field) => {
      const key = parentKey ? `${parentKey}.${field}` : field;
      const value = args[field];

      if (value === undefined || value === null || value === "") {
        missingFields.push(key);
      } else if (typeof value === "object" && !Array.isArray(value)) {
        const nestedMissingFields = this.validateInput(
          value,
          Object.keys(value),
          key
        );
        if (nestedMissingFields !== true) {
          missingFields = missingFields.concat(nestedMissingFields);
        }
      }
    });

    return missingFields.length > 0 ? missingFields : true;
  }
}
class PersonalKyc extends Kyc {
  constructor(
    bvn,
    idCardPhoto,
    idType,
    idValue,
    liveliness,
    nin,
    passportPhoto,
    middleName,
    dob
  ) {
    super(
      bvn,
      idCardPhoto,
      idType,
      idValue,
      liveliness,
      nin,
      passportPhoto,
      middleName,
      dob
    );
  }

  async submitKycForm() {
    try {
      const idCardPhoto = await uploadToREST(this.idCardPhoto);
      const passportPhoto = await uploadToREST(this.passportPhoto);
      const liveliness = await uploadToREST(this.liveliness);
      // console.log(idCardPhoto, "res");
      const data = {
        bvn: this.bvn,
        idCardPhoto: idCardPhoto.destinations[0],
        idType: this.idType,
        idValue: this.idValue,
        nin: this.nin,
        liveliness: liveliness.destinations[0],
        passportPhoto: passportPhoto.destinations[0],
        middleName: this.middleName,
        dob: this.dob,
      };

      //validate again after upload
      const isValid = Kyc.validateInput(data, [
        "bvn",
        "idCardPhoto",
        "idType",
        "idValue",
        "liveliness",
        "nin",
        "passportPhoto",
      ]);
      if (isValid !== true) {
        toast.error("Required fields are missing");
        return;
      }

      const res = await mutate({
        endpoint: "KycForm",
        data: { input: data },
        service: "SETTINGS",
      });
      if (res && res.success) {
        return res;
      } else if (res && !res.success) {
        toast.error(res.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
}

class CompanyKyc extends Kyc {
  constructor(
    bvn,
    idCardPhoto,
    idType,
    idValue,
    liveliness,
    nin,
    passportPhoto,
    middleName,
    dob,
    businessName,
    businessType,
    cacNumber,
    companyDocuments,
    location,
    logo
  ) {
    super(
      bvn,
      idCardPhoto,
      idType,
      idValue,
      liveliness,
      nin,
      passportPhoto,
      middleName,
      dob,
      businessName,
      businessType,
      cacNumber,
      companyDocuments,
      location,
      logo
    );
  }

  static async queryCompanyExec() {
    try {
      await query({
        endpoint: "ListCompanyExec",
        service: "SETTINGS",
        storeKey: "directors",
        payload: {
          type: "DIRECTOR",
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  static async deleteExec(id) {
    try {
      let res = await mutate({
        endpoint: "DeleteCompanyExec",
        data: { execId: id },
        service: "SETTINGS",
      });

      if (res.success) {
        await this.queryCompanyExec();
        return res;
      }
    } catch (e) {
      console.log(e);
    }
  }

  static async createExec(args) {
    const data = {
      title: "",
      fullName: args.firstName + " " + args.middleName + " " + args.lastName, //All three should be joined to fullName
      email: "",
      phoneNumber: "",
      address: "",
      bvn: "",
      idUrl: "",
      idType: "",
      pictureUrl: "",
      signatureImage: null,
      personalDocument: {},
      execType: "DIRECTOR",
      role: "",
    };
    data.title = args.title;
    data.email = args.email;
    data.role = args.role;
    data.idType = args.idType;
    data.phoneNumber = args.phoneNumber;

    if (args.personalDocument[args.idType]) {
      const idData = await uploadToREST(args.personalDocument[args.idType]);
      if (idData.destinations[0]) {
        data.personalDocument[args.idType] = idData.destinations[0];
      }
    }

    if (args.pictureUrl) {
      const url = await uploadToREST(args.pictureUrl);
      if (url.destinations[0]) {
        data.pictureUrl = url.destinations[0];
      } else {
        toast.error("Passport upload failed");
        return false;
      }
    }
    if (args.signatureImage) {
      const signature = await uploadToREST(args.signatureImage);
      if (signature.destinations[0]) {
        data.signatureImage = signature.destinations[0];
      } else {
        toast.error("Signature upload failed");
        return false;
      }
    }
    let errorObj = {};
    try {
      let res = await mutate({
        endpoint: "CreateCompanyExec",
        data: { input: data },
        service: "SETTINGS",
      });

      if (res.success) {
        await CompanyKyc.queryCompanyExec();
      } else {
        errorObj = res.data[data.email];
        for (const err in errorObj) {
          if (errorObj[err] && errorObj[err].length) {
            toast.error(errorObj[err][0]);
          }
        }
        toast.error(res.message);
      }
      return { error: errorObj || null, success: res.success };
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  async submitKycForm() {
    try {
      const idCardPhoto = await uploadToREST(this.idCardPhoto);
      const passportPhoto = await uploadToREST(this.passportPhoto);
      const liveliness = await uploadToREST(this.liveliness);
      const logo = await uploadFileToServer(this.logo);

      // const companyDocuments = await this.companyDocuments.map(async (doc) => {
      //   return {
      //     name: doc.name,
      //     document: await uploadToREST(doc.document),
      //   };
      // });
      const companyDocuments = await Promise.all(
        this.companyDocuments.map(async (doc) => {
          return {
            name: doc.name,
            document: await uploadToREST(doc.document),
          };
        })
      );
      console.log(companyDocuments, "comp-docs");
      // console.log(idCardPhoto, "res");
      const data = {
        personalInput: {
          bvn: this.bvn,
          idCardPhoto: idCardPhoto.destinations[0],
          idType: this.idType,
          idValue: this.idValue,
          nin: this.nin,
          liveliness: liveliness.destinations[0],
          passportPhoto: passportPhoto.destinations[0],
          middleName: this.middleName,
          dob: this.dob,
        },
        companyInput: {
          businessName: this.businessName,
          businessType: this.businessType,
          cacNumber: this.cacNumber,
          companyDocuments: companyDocuments.map((d) => {
            return {
              name: d.name,
              document: d.document.destinations[0],
            };
          }),
          location: this.location,
          logo,
        },
      };

      //validate again after upload
      // const isValid = Kyc.validateInput(data, [
      //   "businessName",
      //   "businessType",
      //   "cacNumber",
      //   "document",
      //   "location",
      //   "logo",
      //   "companyDocuments",
      //   "proofOfAddress",
      //   "cac_document",
      // ]);

      // if (isValid !== true) {
      //   toast.error("Required fields are missing");
      //   return;
      // }

      const res = await mutate({
        endpoint: "KYCCompanyForm",
        data,
        service: "SETTINGS",
      });
      if (res && res.success) {
        window.localStorage.setItem(
          "kycInfoCompleted",
          JSON.stringify({ status: true })
        );
        return res;
      } else if (res && !res.success) {
        toast.error(res.message);
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
}

export const KycClasses = { Kyc, PersonalKyc, CompanyKyc };
